import React, { useEffect } from 'react'
import { BaseHttpService } from '../../services/base'
import GalleryItems from './GalleryItems';

function Galleries() {

    const [data, setData] = React.useState([]);
 
    useEffect(() => {

        _getData()

    }, [])

    const _getData = (page = 1) => {

        BaseHttpService.loadGalleries(page).then(data => {
            setData(data)
            // setCount(data.length)
        })

    }
    return (
        <>
            {data.map(item => (
                <section key={item.id + "cmp"} className="">
                    <div className="container">
                        <GalleryItems gallery={item.id} />
                    </div>
                </section>)
            )}

        </>
    )
}

export default Galleries