import { CloseOutlined, Report, ReportOutlined } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import DeleteDialog from '../../../cms/components/shared/DeleteDialog';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { BaseHttpService } from '../../../services/base';
import { translate, Utils } from '../../../utils';
import GalleryItemCommentLike from '../../gallery/GalleryItemCommentLike';
import ReportCommentModal from '../../modals/campaign/ReportCommentModal';
import LinearLoading from '../../shared/LinearLoading';

function GalleryItemCommentList({ galleryItemId, ...props }) {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);
    const [comments_count, setCommentsCount] = useState(0);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
    const [reportDialogIsOpen, setReportDialogIsOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(0);
    const [refresh, setRefresh] = React.useState(0);


    const { translations } = useContext(LanguageContext);

    const _t = (key) => {
        return translate(key, translations)
    }

    useEffect(() => {

        _getData()

        return () => {
        }
    }, [])

    const _getData = () => {
        if (!galleryItemId) return;
        setLoading(true);
        BaseHttpService.loadGalleryItemComments(galleryItemId).then((items) => {
            setLoading(false);
            setCommentsCount(items.length);
            setData(items)
        });
    }

    const _onDeleteDone = () => {
        var temp = data.filter(item => item.id !== selectedId)
        setData(temp)
        setDeleteDialogIsOpen(false);
        setRefresh(refresh + 1)
    }




    return (
        <div className='comments-list'>
            <LinearLoading key={"ld" + loading} loading={loading} />
            {comments_count > 0 && (
                <div className='comments-count'>
                    {comments_count.toLocaleString('en-us')} Comment{comments_count > 1 ? "s" : ""}
                </div>
            )}
            {data && data?.map(item => (
                <div key={item.id + "cmp"} className='gallery-item-comment'>
                    <div className="gallery-item-comment-avatar">
                        <Avatar src={Utils.getUserImage(item.user)} />
                    </div>
                    <div className="user-info">
                        {item.user.profile ? item.user.profile.first_name : "User"}
                        <span className='ml-2 font-size-10'>{new Date(item.created_at).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric' })}</span>
                        <div className="description">
                            {item.description}
                        </div>
                        <GalleryItemCommentLike galleryItemComment={item} />


                    </div>
                    {Utils.isAdmin() && (
                        <div className="comment-delete" onClick={() => { setSelectedId(item.id); setDeleteDialogIsOpen(true) }} title="delete this comment">
                            <CloseOutlined />
                        </div>
                    )}

                    {Utils.isAuthenticated() && (
                        <div className="report" title="report this comment" onClick={() => { setSelectedId(item.id); setReportDialogIsOpen(true) }}>
                            <ReportOutlined />
                        </div>
                    )}

                </div>
            )
            )}

            <DeleteDialog
                url={`/base/galleryitem/comment/${selectedId}`}
                isOpen={deleteDialogIsOpen}
                key={"mdld" + deleteDialogIsOpen + selectedId}
                setOpen={setDeleteDialogIsOpen}
                notifyDone={_onDeleteDone}
            />

            <ReportCommentModal
                isOpen={reportDialogIsOpen}
                key={"rdio" + reportDialogIsOpen + selectedId}
                setOpen={setReportDialogIsOpen}
                galleryItemCommentId={selectedId}
                onReportedComment={() => {
                    let temp = data.filter(item => item.id !== selectedId)
                    setData(temp)
                }}
            />

        </div>
    )
}

export default GalleryItemCommentList