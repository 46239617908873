import React, { useRef, useState } from 'react'
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { CampaignHttpService } from '../../../services/campaign';
import { translate } from '../../../utils';
import UploadCampaignImageButton from './UploadCampaignImageButton';

function ParticipateForm({ campaign, ...props }) {

  const [loading, setLoading] = useState(false);

  const { translations } = useContext(LanguageContext);
	const _t = (key)=>{
		return translate(key,translations)
	}

  const _onUpload = (participant) => {
    if (props.onSaveSuccess) {
      props.onSaveSuccess(participant);
    }
  };

  const _useUserImageToParticipate = (participant) => {
    _saveCampaignParticipant(props.userImage ?? "assets.images/default.png")
    
  };

  const _saveCampaignParticipant = (image) => {
    if (loading) return;

    setLoading(true);
    var body = {
        "id": props.participantId,
        "index": props.index,
        "campaign": campaign,
        "image": image
    }
    CampaignHttpService.saveCampaignParticipant(body).then(participant => {
        setLoading(false);
        console.log(participant)
        if (participant?.id) {
          props.onSaveSuccess(participant);
        }
    });
};

  




  return (
    <form className='styled_form' >
      <div className="row" >
        <div className="col-md-12 d-flex flex-column align-items-center" >
          {(props.userImage && false ) ? (
            <>
              <div className="d-flex flex-column align-items-center ">
              <h4>use my avatar</h4>
                <img src="assets/images/default.png" onClick={() => { _useUserImageToParticipate() }} alt="use avatar" className="login-user-avatar" />
              </div>
            </>)
            :
            (<>
              <div className="p-2 mb-3 text-center">
                <UploadCampaignImageButton campaign={campaign} onUpload={_onUpload} index={props.index} />
              </div>
              
            </>
            )}


        </div>

        <div className="col-md-12" >
          {loading && (
            <button className="btn p-2" type="button" disabled>
              <span className="spinner-grow text-warning spinner-grow-sm" role="status" aria-hidden="true"></span>
              <span className="sr-only">Loading...</span>
            </button>
          )}

        </div>
      </div>
    </form>
  )
}

export default ParticipateForm