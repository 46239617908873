import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translate } from '../../../utils';
import ReportCommentForm from '../../forms/gallery/ReportCommentForm';
import CustomModal from '../CustomModal';

const report_choices = [
  {
    title: "False Information"
  },
  {
    title: "Violence or dangerous organization"
  },
  {
    title: "Scam or fraud"
  },
  {
    title: "Intellectual property violation"
  },
  {
    title: "Sale of illegal or irreguated goods"
  },
  {
    title: "Suicide or self-injury"
  },
  {
    title: "Something else"
  }
]

function ReportCommentModal({ galleryItemCommentId, ...props }) {

  const [animationClass, setAnimationClass] = useState("");
  const [selectedReportChoice, setSelectedReportChoice] = useState("");

  const [refresh, setRefresh] = useState(0);
  const [step, setStep] = useState(0);

  const { translations } = useContext(LanguageContext);
  const _t = (key) => {
    return translate(key, translations)
  }

  useEffect(() => {
    setRefresh(refresh + 1)
  }, [galleryItemCommentId])

  const _resetAnimation = () => {
    setTimeout(function () {
      setAnimationClass("")
    }, 500);
  }

  const _next = (item) => {
    setAnimationClass("fadeInRight")
    setSelectedReportChoice(item)
    setStep(step + 1)
    _resetAnimation()
    setRefresh(refresh + 1)
  }

  const _previous = () => {
    setAnimationClass("fadeInLeft")
    setSelectedReportChoice(null)
    setStep(step - 1)
    _resetAnimation()
    setRefresh(refresh + 1)
  }

  return (
    <>
      <CustomModal className="nice-scroll" key={"cmp" + props.isOpen} setOpen={(value) => props.setOpen(value)}
        open={props.isOpen > 0}  >

        <div className={`report-comment  ${step === 0 ? "" : "hide"}`}>
          <h2>{_t("Why are you reporting this comment")}</h2>
          <p>{_t("Your report is anonymous. If someone is in immediate danger, call the local emergency services - don't wait")}</p>
          <ul className='report-list'>
            {report_choices && report_choices.map(item =>
            (
              <li key={item.title} onClick={() => {
                _next(item)
              }} >
                {item.title}
                <i className="fa fa-chevron-right"></i>
              </li>
            )
            )}

          </ul>
        </div>
        <div className={`report-comment-step-2 animated ${animationClass} ${step === 1 ? "" : "hide"}`}>
          <div className="back" onClick={_previous}>
            <i className="fa fa-chevron-left"></i>
          </div>
          <h2>{_t("Why are you reporting this comment")}</h2>
          <p>{_t("Please tell us why do you think this comment should be reported?")}</p>
          <ReportCommentForm galleryItemCommentId={props?.galleryItemCommentId} title={selectedReportChoice?.title} callback={() => { _next(); props.onReportedComment(); }} />
        </div>

        <div className={`report-comment-step-3 animated ${animationClass} ${step === 2 ? "" : "hide"}`}>
          <h2>{_t("We recieved your report.")}</h2>
          <p>{_t("Thank you for your contribution. we will inform you about the results ASAP")}</p>
          <Button onClick={()=>{props.setOpen(false)}} className="pull-right">{_t("Close")}</Button>
        </div>
      </CustomModal>

    </>
  )
}

export default ReportCommentModal