import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextareaAutosize, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Done from "../../shared/Done";
import Error from "../../shared/Error";
import Loading from "../../shared/Loading";
import { BaseHttpService } from "../../../../services/base";
import CustomDatePicker from "../widgets/DatePicker";
import SelectCity from "../widgets/select/SelectCity";

function GalleryItemForm({ id, gallery, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      BaseHttpService.loadGalleryItem(id).then(item => {
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)

    for (const key in item) {
      setValue(key, item[key])
    }

    setImage(item.image)

    if (item.city) { // Because city is an object
      setValue("city", item.city.id)
      setRefresh(refresh + 1)
    }

    if (item.end_city) {
      setValue("end_city", item.end_city.id)
      setRefresh(refresh + 1)
    }
  }

  const _previewFile = (file) => {

    const objectUrl = URL.createObjectURL(file)
    setImage(objectUrl);
    setRefresh(refresh + 1)
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    if (!data.name) {
      setError("Please provide a name");
      return;
    }

    var birth_date = new Date(data?.birth_date).toISOString()
    var second_date = new Date(data?.second_date).toISOString()

    var body = {
      "id": id ? id : data.id,
      "name": data.name,
      "gallery": gallery,
      "description": data.description,
      "birth_date": birth_date,
      "second_date": second_date,
      "city": data.city ?? null,
      "end_city": data.end_city ?? null,
      "file": file,
    }

    console.log(body)

    setDone(false)
    setLoading(true)
    BaseHttpService.saveGalleryItem(body).then(response => {
      console.log(response)
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  const _handleFileClick = (e) => {
    e.preventDefault();
    fileRef.current.click();

  }

  const _handleFileChange = (e) => {
    var file = e.target.files[0]
    if (e.target.files) {
      setFile(file)
      _previewFile(file)
    }

  }
  return (
    <div>
      <form className="" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    fullWidth
                    label="Name"
                    value={watch('name')}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...register("name", { required: true })}
                  />
                </div>
              </div>
              <div className="col-md-6">

                <div className="form-group">
                  <CustomDatePicker label={"Date of birth"} onChange={(value) => { setValue('birth_date', value) }} value={watch('birth_date')} />
                </div>
              </div>
              <div className="col-md-6">

                <div className="form-group">
                  <CustomDatePicker label={"Date of death"} onChange={(value) => { setValue('second_date', value) }} value={watch('second_date')} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <SelectCity placeholder="Place of birth" key={refresh + "sc"} register={register} value={watch('city') ?? ""} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <SelectCity placeholder="Place of death" name={"end_city"} key={refresh + "sc"} register={register} value={watch('end_city') ?? ""} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <TextareaAutosize
                    className="form-control"
                    label="Description"
                    placeholder="description"
                    value={watch('description')}
                    variant="outlined"
                    {...register("description", { required: false })}
                  />
                </div>

              </div>

            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group mt-1 d-flex justify-content-center">
              <img src={image ? image : `/assets/images/default.png`} alt="Upload" onClick={_handleFileClick} className="modal-gallery-item-img img-rounded" />
              <input type="file" ref={fileRef} className="hide" onChange={_handleFileChange} />
            </div>

            <div className="form-group mt-3 text-right mb-1  d-flex justify-content-center">
              <Button
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                startIcon={editingItem ? <EditIcon /> : <AddIcon />}
              >
                {editingItem ? "Save Changes" : "Add Picture"}
              </Button>


            </div>
          </div>

        </div>


        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <Loading />}
        </div>
      </form>
    </div>
  );
}

export default GalleryItemForm;
