import React, { useState } from 'react'
import RegisterForm from '../forms/user/RegisterForm';
import CustomModal from './CustomModal';

function RegisterModal(props) {

  const [loginModalIsOpen,setLoginModalIsOpen] = useState(false);

  const handleClose = () => {
    props.setOpen(false);
  };


  return (
    <>
    <CustomModal className="" key={props.isOpen} setOpen={(value) => props.setOpen(value)}
      open={props.isOpen} >
       
        <RegisterForm setOpen={props.setOpen} goToLogin={props.goToLogin} onLoggedIn={(user) => {if (props.onLoggedIn) props.onLoggedIn(user) }} />

    </CustomModal>
    
    </>
  )
}

export default RegisterModal