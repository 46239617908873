import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import { useParams } from "react-router-dom";
import GalleryForm from '../../components/forms/gallery/GalleryForm'
import GalleryItems from './GalleryItems'

function GalleryDetails() {

  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {

    _getData()

    return () => {
    }
  }, [])

  const _getData = () => {
    // setLoading(true);
  }


  return (
    <div id="wrapper">
      <Header />
      <div id="content" className='container mt-4 pb-0'>
        <div className='box'>
          <div className="box-header mb-5">
            <h5>Gallery Details</h5>
            <div className="btns">
              {/* <Link to="/cms/campaign" className="btn"><i className="fa fa-plus"></i></Link> */}
            </div>
          </div>
          <div className="box-body">
            <div className="row">
              {/* <MainMenu /> */}
              <div className="col-md-12">
                <GalleryForm key={"cam" + loading} id={id} />
                <GalleryItems gallery={id} />
              </div>
            </div>
          </div>
        </div>


      </div>

      <Footer />
    </div>
  )
}

export default GalleryDetails