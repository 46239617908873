import { createContext, useEffect, useState } from "react";
import { BaseHttpService } from "../services/base";
import { HttpService } from "../services/http";
import { Utils } from "../utils";

export const UserContext = createContext();

export const UserContextProvider = (props) => {

  const [authenticate, setAuthenticate] = useState();
  const [profile, setProfile] = useState();

  const urlParams = new URLSearchParams(window.location.search); 
  const signout = urlParams.get('signout')

  if(signout){
    HttpService.signOut();
    setAuthenticate(null) // why this is not working ?

    //removing the ?signout=1 from the url
    Utils.clearUrl()
  }

  const _getMe = () => {
    BaseHttpService.loadMe().then(data => {
        if(data && data.length > 0) {
          setProfile(data[0])
        }
    })

}
  

  let localStoageToken = window.localStorage.getItem("authenticate");
  useEffect(() => {
    if (localStoageToken) {
      setAuthenticate(JSON.parse(localStoageToken));
      _getMe()
    }

   

  }, []);

  return (
    <UserContext.Provider value={{ setAuthenticate, authenticate , profile }}>
      {props.children}
    </UserContext.Provider>
  );
};
