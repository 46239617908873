import { CircularProgress } from '@mui/material'
import React from 'react'

function LinearLoading({ loading }) {
    return (
        <>
            {loading &&
                <div className='linear-loading'>
                    <CircularProgress style={{width:20, height:20}} color="inherit" />
                </div>
            }
        </>

    )
}

export default LinearLoading