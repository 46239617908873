import { Navigate, Route, Routes } from "react-router-dom";
import { HttpService } from "../services/http";
import Home from "../pages/shared/Home";
import Dashboard from "../cms/pages/Dashboard";
import Login from "../cms/pages/user/Login";
import Campaign from "../cms/pages/campaign/Campaign";
import Users from "../cms/pages/user/Users";
import CampaignDetails from "../cms/pages/campaign/CampaignDetails";
import UserDetails from "../cms/pages/user/UserDetails";
import Register from "../cms/pages/user/Register";
import Gallery from "../cms/pages/gallery/Gallery";
import GalleryPage from "../pages/gallery/GalleryPage";
import Settings from "../cms/pages/setting/Settings";
import About from "../pages/shared/About";
import CityPage from "../cms/pages/setting/city/CityPage";
import SettingsGeneral from "../cms/pages/setting/SettingsGeneral";
import GalleryDetails from "../pages/gallery/GalleryDetails";
import CmsGalleryDetails from "../cms/pages/gallery/GalleryDetails";

const MainRoutes = () => {
  const localStorageToken = HttpService.getToken()
  const localStorageInfo = HttpService.getUser()


  const _adminRoutes = () => {
    return (

      <>
        <Route path="/cms/dashboard" element={<Dashboard />} />
        <Route path="/cms/campaigns" element={<Campaign />} />
        <Route path="/cms/campaign/" exact element={<CampaignDetails />} />
        <Route path="/cms/campaign/:id" element={<CampaignDetails />} />
        <Route path="/cms/users" element={<Users />} />
        <Route path="/cms/user/:id" element={<UserDetails />} />
        <Route path="/cms/user/add" element={<UserDetails />} />
        <Route path="/cms/gallery/" exact element={<Gallery />} />
        <Route path="/cms/gallery/add" exact element={<GalleryDetails />} />
        <Route path="/cms/gallery/:id" element={<CmsGalleryDetails />} />
        <Route path="/cms/settings" element={<Settings />} />
        <Route path="/cms/settings/general" element={<SettingsGeneral />} />
        <Route path="/cms/city/" exact element={<CityPage />} />
        <Route path="/cms/city/:id" element={<CityPage />} />


      </>
    )
  }



  const _insurerRoutes = () => {
    return (<>

      {/* <Route index element={<HomePage />} />
      <Route path="/expense" element={<Expense />} />
      <Route path="/insured" element={<Insured />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/help" element={<Help />} /> */}

    </>)
  }


  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<About />} />
      <Route path="/register" element={<Register />} />
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/gallery/:id" element={<GalleryDetails />} />
      <Route path="/cms" element={localStorageInfo?.role === "admin" ? <Dashboard /> : <Login />} />
      {localStorageToken && localStorageInfo?.role === "admin" ? _adminRoutes() :
        localStorageInfo?.role === "insurer" ? _insurerRoutes() : (<></>)}
    </Routes>
  );
};

export default MainRoutes;
