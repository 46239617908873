import { Favorite } from '@mui/icons-material';
import React from 'react'
import { useEffect } from 'react';
import { BaseHttpService } from '../../services/base';

function GalleryLike({ galleryItem, likeChanged }) {

    const [loading, setLoading] = React.useState(false);
    const [done, setDone] = React.useState(false);
    const [liked, setLiked] = React.useState(galleryItem?.is_liked);
    const [likes_count, setLikesCount] = React.useState(galleryItem?.likes_count);


    const _like = () => {
        setLoading(true)
        setLiked(!liked)
        likeChanged(!liked)


        BaseHttpService.likeGalleryItem({ gallery_item: galleryItem.id }).then(response => {
            if (response.id) {
                setLiked(true)

                if (!liked) {
                    setLikesCount(likes_count + 1)
                }
            }
            else {
                setLiked(false)
                if (liked) {
                    setLikesCount(likes_count - 1)
                }
            }
        })
        setDone(true)
        setLoading(false)
    }

    return (
        <div className="gallery-item-like" onClick={(e) => { e.preventDefault(); _like() }}>
            {likes_count}
            <div className={`heart ${liked ? "liked" : ""}`}>
                <Favorite />
            </div>

        </div>
    )
}

export default GalleryLike