import React from 'react'

function Footer() {
  return (
    <footer className="p-0">
            <div className="subfooter" >
                <div className="container" >
                    <div className="row" >
                        <div className="col-md-12 footer-copyright" >
                            Copyright 2023 &nbsp; © &nbsp;
                            {/* <span className="id-color">Jina Gallery</span> */}
                            <img src="https://jina.nu/assets/images/logo.png" alt="Jina.nu" />
                        </div>
                        <div className="col-md-12" >
                            <div className="social-icons" >
                                <a href="https://www.facebook.com/profile.php?id=100092087274338" target='_blank' rel='noreferrer'
                                ><i className="fa fa-facebook fa-lg"></i></a>
                                <a href="https://www.twitter.com/revolution_jina" target='_blank' rel='noreferrer'><i className="fa fa-twitter fa-lg"></i></a>
                                <a href="https://www.instagram.com/jinarevolutionart"><i className="fa fa-instagram fa-lg"></i></a>
                                {/* <a href="#"><i className="fa fa-rss fa-lg"></i></a> */}
                                {/* <a href="#"><i className="fa fa-google-plus fa-lg"></i></a> */}
                                {/* <a href="#"><i className="fa fa-skype fa-lg"></i></a> */}
                                {/* <a href="#"><i className="fa fa-dribbble fa-lg"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <a href="#" id="back-to-top"></a>
        </footer>
  )
}

export default Footer