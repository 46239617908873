import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import GalleryItemView from '../../components/gallery/GalleryItemView';
import LoginModal from '../../components/modals/LoginModal';
import { BaseHttpService } from '../../services/base'
import { HttpService } from '../../services/http';
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import MobileMenu from '../../components/shared/MobileMenu';
import { Utils } from 'utils';

function GalleryDetails() {

    const { id } = useParams();
    const [data, setData] = React.useState(null);
    const [galleryItems, setGalleryItems] = React.useState(null);

    const [loading, setLoading] = React.useState(false);
    const [me, setMe] = React.useState(null);
    const [refresh, setRefresh] = React.useState(0);
    const [refresh_likes, setRefreshLikes] = React.useState(0);

    const [loginModalIsOpen, setLoginModalIsOpen] = React.useState(false);

    useEffect(() => {
        
        Utils.scrollToTop();
        _getData()
        _getGalleryItems()

    }, [])

    
    const _getGalleryItems = (page = 1, sort_by = "-birth_date") => {
        BaseHttpService.loadGalleryItems(id, page, sort_by).then(data => {
            setGalleryItems(data)
        })

    }

    const _likeChanged = (value, id = data?.id) => {
        setLoading(true)
        var user = HttpService.getUser()
        setLoading(false)

        if (!user) {
            setLoginModalIsOpen(true)
            return;
        }
        else {
            _performLikeChanged(value)
        }

    }

    const _performLikeChanged = (value, id = data.id) => {

        let gitems = galleryItems.map((item) => {
            if (item.id === id) {
                return { ...item, is_liked: value, likes_count: value ? item.likes_count+=1 : item.likes_count-=1 }
            }
            return item
        })
        setGalleryItems(gitems)

        let temp = { ...data, is_liked: value, likes_count: value ? data.likes_count+=1 : data.likes_count-=1 }
        setData(temp)
        setRefreshLikes(refresh_likes + 1)
    }

    const _next = () => {
        let _index = galleryItems.findIndex(item => item.id === data.id) + 1
        if (_index === galleryItems.length) _index = 0
        setData(galleryItems[_index])
        // setRefresh(refresh + 1)
    }
    const _previous = () => {

        let _index = galleryItems.findIndex(item => item.id === data.id) - 1
        if (_index < 0) _index = galleryItems.length - 1
        setData(galleryItems[_index])
        // setRefresh(refresh + 1)
    }

    const _getData = () => {

        BaseHttpService.loadGalleryItem(id).then(data => {
            setData(data)
            if (data.name) {
                document.title = document.title + " | " + data.name
                setRefresh(refresh + 1)
            }
        })

    }
    return (
        <>
            <div id="wrapper">
                <Header autoShow={false} />
                <div id="content" className="no-bottom  ">
                    <MobileMenu />
                    {data && (
                        <GalleryItemView key={"givv" + refresh_likes} galleryItem={data} likeChanged={_likeChanged} next={_next} previous={_previous} />
                    )}
                    <LoginModal key={"lm" + loginModalIsOpen}
                        onLoggedIn={() => { setLoginModalIsOpen(false) }}
                        isOpen={loginModalIsOpen} setOpen={setLoginModalIsOpen}

                    />
                </div>
                <Footer />
            </div>


        </>
    )
}

export default GalleryDetails