import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import MainMenu from '../../components/shared/MainMenu'
import { Link, useParams } from "react-router-dom";
import SettingForm from '../../components/forms/settings/SettingForm'
import { LocationCity, Person2Outlined, Pin, PinDrop, PinDropOutlined, SettingsApplications, SettingsOutlined } from '@mui/icons-material'

function Settings() {


  return (
    <div id="wrapper">
      <Header />
      <div id="content" className='container mt-4 pb-0'>
        <div className='box'>
          <div className="box-header mb-5">
            <h5>Settings</h5>
            <div className="btns">
            </div>
          </div>
          <div className="box-body">
            <div className="row">
              <div className="col-md-9">
                <div className="setting-items-container">
                  <Link to="/cms/settings/general/" className="setting-item">
                    <SettingsOutlined className='animated' />
                    <div>General</div>
                  </Link>
                  <Link to="/cms/users/" className="setting-item">
                    <Person2Outlined />
                    <div>Users</div>
                  </Link>
                  <Link to="/cms/city/" className="setting-item">
                    <PinDropOutlined />
                    <div>Cities</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      <Footer />
    </div>
  )
}

export default Settings