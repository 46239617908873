import { useEffect } from 'react';
import { useState } from 'react';


export const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);
  const [refresh, setRefresh] = useState(0);


  const togglePlayMusic = () => setPlaying(!playing);

  const restartMusic = () => {
    console.log('ended')
    setRefresh(refresh+1)
    console.log(playing)
    setPlaying(true)
    // audio.play()
  };

  useEffect(() => {
    // audio.loop = loop;
    playing ? audio.play() : audio.pause();
    // _replay()

  },
    [playing]
  );

  useEffect(() => {

    audio.addEventListener('ended', () => {
      setPlaying(false)

    });
    return () => {
      audio.removeEventListener('ended', () => {
        setPlaying(false)
      });
    };
  }, []);

  return [playing, togglePlayMusic];
};
