import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translate } from '../../../utils';

function SelectRole({onChange}) {
    
    const [value,setValue] = useState(1)
    const { translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }

    const handleChange = (e) => {
        setValue(e.target.value)
        onChange(e.target.value);
    }
    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="select_role">Role</InputLabel>
                <Select
                    labelId="select_role"
                    id="select_role_select"
                    value={value}
                    label="Role "
                    onChange={handleChange}
                >
                    <MenuItem value={"participant"}>Participant</MenuItem>
                    <MenuItem value={"admin"}>Administrator</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default SelectRole