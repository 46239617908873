import { PersonPinCircle } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { CampaignHttpService } from '../../../services/campaign';
import { HttpService } from '../../../services/http';
import { Utils } from '../../../utils';
import EditCampaignImageButton from '../../forms/campaign/EditCampaignImageButton';
import UploadCampaignImageButton from '../../forms/campaign/UploadCampaignImageButton';
import CustomModal from '../CustomModal';

function ParticipantViewModal({ participant, onDelete, userId, ...props }) {

  var url = `https://jina.nu/?index=${props.index}`;
  var text = "I participated in an online campaign"
  var textWithUrl = text + "\n" + url

  const [isMine, setIsMine] = useState(false)
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState(participant?.avatar ? participant?.avatar : participant?.image ? participant?.image : "")

  var user = HttpService.getUser()

  useEffect(() => {
    if (user?.id === participant?.user.id || user?.id === participant?.user) {
      setIsMine(true)
    }
  }, [user])


  return (
    <>
      <CustomModal className="p-0 " medium key={"pvm" + props.isOpen} setOpen={(value) => props.setOpen(value)}
        open={props.isOpen}
      >

        <div >
            <div className=" p-0 mb-3 d-flex justify-content-center">
              <div className="participant-image-container picframe">
                <img src={
                  image
                }
                  loading="lazy" alt="" />

                <div className="participant-image-container-caption">
                  <div>{participant?.user?.profile?.first_name && (participant?.user?.profile?.first_name + " " + participant?.user?.profile?.last_name)}</div>
                  <span title="Index in Jina's Website"> <i className='fa fa-user'></i> {participant && participant.index}</span>
                </div>
              </div>
            </div>
            <div className="mb-3 text-center">
              <div className=" social-icons-gallery social-icons-home color-gray2">
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target="_blank" title='Share on Facebook' rel="noreferrer" ><i className="fa fa-facebook fa-lg"></i></a>
                <a href={`http://twitter.com/share?text=${text}&url=${url}&hashtags=JinaAmini`} title='Share on Twitter' target="_blank" rel="noreferrer"><i className="fa fa-twitter fa-lg"></i></a>
                <a href={`https://telegram.me/share/url?url=${url}&text=${text}`} target="_blank" title='Share on Telegram' rel="noreferrer"><i className="fa fa-telegram fa-lg"></i></a>
                <a href={`whatsapp://send?text=${textWithUrl}`} data-action="share/whatsapp/share" title='Share on Whatsapp' target="_blank" rel="noreferrer"><i className="fa fa-whatsapp fa-lg"></i></a>
              </div>
              {/*              
              {loading && (
                  <button className="btn p-2" type="button" disabled>
                    <span className="spinner-grow text-warning spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span className="sr-only">Loading...</span>
                  </button>
                )} */}

              {isMine && (
                <EditCampaignImageButton participantId={participant?.id} campaign={props.campaign} onUpload={(response) => { setImage(response.image); }} onDelete={(response) => { setImage(null); onDelete(); }} index={props.index} />
              )}

              <div className='relative'>
                <button type="button" className="btn-close btn-close-white btn-colse-footer" onClick={() => { props.setOpen(false) }} data-bs-dismiss="modal" aria-label="Close"></button>
              </div>

            </div>
        </div>

      </CustomModal>


    </>
  )
}

export default ParticipantViewModal