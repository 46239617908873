import React, { useState } from 'react'
import ParticipateForm from '../../forms/campaign/ParticipateForm';
import CustomModal from '../CustomModal';

function ParticipateModal(props) {



  return (
    <>
    <CustomModal className="my-4" key={"cmp"+props.isOpen} setOpen={(value) => props.setOpen(value)}
      open={props.isOpen>0}  >
       
        <ParticipateForm  userImage={props.userImage} campaign={props.campaign} onSaveSuccess={props.onSaveSuccess} index={props.isOpen} />

    </CustomModal>
    
    </>
  )
}

export default ParticipateModal