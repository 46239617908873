import React, { useEffect } from 'react'
import CampaignParticipants from '../components/list/campaign/CampaignParticipants'
import Footer from '../components/shared/Footer'
import Header from '../components/shared/Header'

function Dashboard() {
  
  
  return (
    <div id="wrapper">
        <Header />
       
        <div id="content" className='container mt-4 pb-0'>
        <div style={{ display: "block"}}>
        </div>
            <div className="row">
                {/* <MainMenu /> */}
                <div className="col-md-12">
                <div className='box'>
                  <div className="box-header">
                  <h5>Dashboard</h5>
                  </div>
                  <div className="box-body">
                    
                  <p>New participants</p>
                    <CampaignParticipants />
                    
                  </div>
              </div>
                </div>
            </div>

        </div>

        <Footer />
    </div>
  )
}

export default Dashboard