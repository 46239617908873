import React, { useEffect, useState } from 'react'
import { CampaignHttpService } from '../../services/campaign';
import LoginModal from '../modals/LoginModal';
import ParticipateModal from '../modals/campaign/ParticipateModal';
import ParticipantViewModal from '../modals/campaign/ParticipantViewModal';
import SearchCampaignModal from '../modals/campaign/SearchCampaignModal';
import MobileMenu from './MobileMenu';
import { translate, Utils } from '../../utils';
import { HttpService } from '../../services/http';
import { useMemo } from 'react';
import { useAudio } from './userAudio';
import { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import { BaseHttpService } from '../../services/base';
import { Link, useNavigate } from 'react-router-dom';


export default function CampaginView(props) {

  const addingImageIsAllowed = false || Utils.isAdmin();
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [participateModalIsOpen, setParticipateModalIsOpen] = useState(false);
  const [participantViewModalIsOpen, setParticipantViewModalIsOpen] = useState(false);
  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);
  const [already_participated, setAlreadyParticipated] = useState(false);

  const [numberOfSquares, setNumberOfSquares] = useState(2500);
  const [squareMargin, setSquareMargin] = useState("4px");
  const [squareWidth, setSquareWidth] = useState("1%");
  const [squareOpacity, setSquareOpacity] = useState("0.15");
  const [startIndex, setStartIndex] = useState(0);

  const [animationClass, setAnimationClass] = useState("square-zoomIn");
  const [isPlaying, setIsPlaying] = useState(false);


  const [loading, setLoading] = useState(false);
  const [squaresAreVisible, setSquaresAreVisible] = useState(true);


  const [campaigns, setCompaigns] = useState(null);
  const [participants, setParticipans] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [toAnimateIndex, setToAnimateIndex] = useState(null);
  const [playing, togglePlayMusic] = useAudio("/assets/anim/1.jna");
  const [paused, setPaused] = useState(true);
  const [show_tooltip, setShowTooltip] = useState(false);

  const { translations } = useContext(LanguageContext);
  const _t = (key) => {
    return translate(key, translations)
  }

  const navigate = useNavigate();


  useEffect(() => {

    // Loop music when music ended and user didn't click to pause
    if (!playing && !paused)
      togglePlayMusic()

  },
    [playing]
  );

  const [campaign, setCompaign] = useState([]);

  var user = HttpService.getUser()

  const [user_image, setUserImage] = useState("");


  const shouldLogin = Utils.getUrlParameter('login')
  const loop = true;


  useEffect(() => {
    _getData()

    if (shouldLogin != null || props.login) {
      setLoginModalIsOpen(true)
      Utils.clearUrl()
    }
  }, [])

  const _getData = () => {
    setLoading(true)

    if (participants?.length > 0) return;

    // _loadParticipants();
    _loadCampaign();
    _getGalleryItems();


  }

  const _getGalleryItems = () => {

    BaseHttpService.loadGalleryItems(null,1,"-birth_date").then(data => {
      _createParticipantsFromGalleryItems(data)
    })

  }

  const _createParticipantsFromGalleryItems = (GalleryItems) => {
    const participants = [];
  
    // Generate a random index from GalleryItems and add it to participants
    for (let i = 0; i < numberOfSquares; i++) {
      const randomIndex = Math.floor(Math.random() * GalleryItems.length);
      const randomItem = GalleryItems[randomIndex];
      participants.push({
        id: randomItem.id,
        index : i,
        isGallery : true,
        image: randomItem.image,
        thumb: randomItem.thumb,
      });
    }
    setParticipans(participants);
    // return participants;
  };

  const _setSquareOptions = (campaign) => {
    setNumberOfSquares(campaign.number_of_squares ?? 2500)
    setSquareMargin(campaign.square_margin ?? "4px")
    setSquareOpacity(campaign.square_opacity)
    setSquareWidth(campaign.square_width)
  }

  const _onLoggedIn = () => {
    console.log("you logged in successfully")

    user = HttpService.getUser()

    setLoginModalIsOpen(false)

    var participant = _getParticipantByIndex(selectedIndex)

    if (participant) {
      // View the participant details in a modal
      setParticipantViewModalIsOpen(true)
    }
    else {
      if (selectedIndex) {
        if (participants?.length > 0 && user) {
          // console.log(user)
          var me = participants.find(participant => participant.user.id === user?.id);
          // console.log(me)
          if (me) {
            setSelectedIndex(me.index)
            setParticipantViewModalIsOpen(true);
          }
          else {
            setParticipateModalIsOpen(selectedIndex);
          }
        }
      }
      else {
        setUserImage(user?.image)
        _onAddNew()
      }
    }
  }

  const _handleSquareClick = (index) => {
    setSelectedIndex(index);
    setToAnimateIndex(-1)
    setIsPlaying(false)

    var participant = _getParticipantByIndex(index)

    

    if (participant) {

      
      if(participant?.isGallery){
        navigate("/gallery/" + participant.id);
        return false;
      }

      // View the participant details in a modal
      setParticipantViewModalIsOpen(true)
    }
    else {
      // square is empty and user can participate in campaign
      
      if(!addingImageIsAllowed){
        return false;
      }

      user = HttpService.getUser()
      if (!user) {
        setLoginModalIsOpen(true);
      }
      else {


        var me = participants.find(participant => participant?.user.id === user?.id);
        // alert(user.id)
        // console.log(participants)
        if (me) {
          console.log("your index is :" + me.index)
          setAlreadyParticipated(me)
          setSelectedIndex(me.index)
          setParticipantViewModalIsOpen(true);
        }
        else {
          setSelectedIndex(index)
          setParticipateModalIsOpen(index);
        }
      }
    }


  }

  const _isAlreadyParticipated = (participants) => {
    if (participants?.length > 0 && user) {
      var me = participants.find(participant => participant.user.id === user?.id);
      if (me) {
        setAlreadyParticipated(me)
      }
    }
  }

  const _getCampaignParticipants = (campaign) => {

    if (!campaign) return null;
    CampaignHttpService.loadCampaignParticipants(campaign).then(participants => {
      setParticipans(participants)
      _isAlreadyParticipated(participants)


      _handlePlayMusicTooltip()
      // Show Shared index,
      if (participants && props.toShowIndex) {
        let index = parseInt(props.toShowIndex)

        if (index === undefined) index = 0;

        if (index > 1000) {
          setStartIndex(index - 1000)
        }
        else {
          setStartIndex(0)
        }

        // var participant = participants.find(item => item.index == index)
        // _showIndex(participant, index);
        _animateIndex(index)
      }

    })
  }

  const _handlePlayMusicTooltip = (tooltip) => {
    let _tok = Utils.getShowMusicTooltipToken()
    if (!_tok) {
      setShowTooltip(true)
      Utils.setShowMusicTooltipToken()
    }
  }

  const _showIndex = (participant, index) => {
    setSelectedIndex(index)

    if (participant) {
      setParticipantViewModalIsOpen(true)
    }
  }


  const _getParticipantByIndex = (index) => {
    if (!participants) return null;

    var participant = participants.find(item => item.index === index)

    // Fake Participant
    // if (!participant) {
    //   var imageSamples = participants.filter(item => item.image !== "")
    //   participant = imageSamples[Math.floor(Math.random() * imageSamples.length)];
    // }

    if (participant) {
      return participant;
    }
    return null;
  }



  const _onParticipate = (index) => {
    setSearchModalIsOpen(false)
    setSelectedIndex(index);
    user = HttpService.getUser()

    if (!user) {
      setLoginModalIsOpen(true);
    }
    else {
      setParticipateModalIsOpen(index);
    }
  }




  const _animateIndex = (index) => {
    setSearchModalIsOpen(false)
    index = index - startIndex
    setToAnimateIndex(index)

    // Utils.clearUrl()
    // setToAnimateIndex(false)

  }

  const _onPlayMusic = () => {
    setToAnimateIndex(0)
    setIsPlaying(!playing)
    setPaused(!paused)
    togglePlayMusic()

  }




  const _onAddNew = () => {

    setIsPlaying(false)

    let index;
    for (let i = 0; i < 7000; i++) {
      let participant = participants?.find(item => item.index === i);
      if (!participant) {
        index = i;
        break;
      }
    }

    if (!index) {
      index = participants?.length > 0 ? participants.length : 1;
    }



    user = HttpService.getUser()


    setToAnimateIndex(-1)

    if (!user) {
      setSelectedIndex(index)
      setLoginModalIsOpen(true);

    }
    else {
      if (already_participated) {
        setSelectedIndex(already_participated.index)
        setParticipantViewModalIsOpen(true);
      }
      else {
        setSelectedIndex(index)
        setParticipateModalIsOpen(index);
      }
    }

  }



  const _getDelay = (index) => {
    const randomNumber = Math.floor(Math.random() * 1600) + 1
    var _delay = randomNumber * 0.5 + 2;
    return _delay;
  }

  const Squares = () => {

    // console.log("rendering squares")

    const cachedValue = useMemo(() =>
    (
      Array(numberOfSquares).fill().map((x, index) => {
        index = index + startIndex
        var participant = _getParticipantByIndex(index + 1);

        var _delay = _getDelay(index);

        let _scaleUp = toAnimateIndex - 1 === index;
        if (_scaleUp) {
          _delay = 0;
        }
        return (
          SquareItem(index, participant, _scaleUp, _delay)
        )
      }
      ))
      , [])

    return cachedValue
  }



  return (

    <>

      <section
        id="section-home" onClick={() => { setShowTooltip(false) }} className='squares nice-scroll p-0' style={{ backgroundImage: `${campaign ? `url(${campaign.image})` : "url(assets/images/background/jina.jpg)"}` }}>
        <MobileMenu />
        <CampaignMenu />
        <div className="campaign-mobile-menu show-on-small">
        {addingImageIsAllowed && (
          <button onClick={_onAddNew} className="btn mb-2" title="Add your photo"><i className='fa fa-plus'></i></button>
        )}
          <button onClick={_onPlayMusic} className="btn mb-2" title={`${isPlaying ? 'Pause' : 'Play'}`}><i className={`fa fa-music ${playing ? 'color-gold' : ''}`}></i></button>
          {/* <button onClick={() => { setIsPlaying(false); setSearchModalIsOpen(true) }} title="Search in campaign" className="btn"><i className='fa fa-search'></i></button> */}

        </div>
        <Squares />


      </section>
      {loginModalIsOpen && (
        <LoginModal key={"lm" + loginModalIsOpen}
          onLoggedIn={_onLoggedIn}
          isOpen={loginModalIsOpen} setOpen={setLoginModalIsOpen}

        />
      )}
      {participateModalIsOpen && (
        <ParticipateModal key={"pm" + participateModalIsOpen}
          campaign={campaign?.id}
          userImage={user_image}
          onSaveSuccess={(campaignParticipant) => {
            var temp = participants;
            temp.push(campaignParticipant);
            setParticipans(temp);
            setAlreadyParticipated(campaignParticipant)
            setParticipateModalIsOpen(false);
            // Shows participant of the selectedIndex
            setParticipantViewModalIsOpen(true);

          }}
          isOpen={participateModalIsOpen} setOpen={setParticipateModalIsOpen} />
      )}


      {participantViewModalIsOpen && (
        <ParticipantViewModal key={"pntm" + participantViewModalIsOpen}
          index={selectedIndex}
          isOpen={participantViewModalIsOpen}
          setOpen={setParticipantViewModalIsOpen}
          participant={_getParticipantByIndex(selectedIndex)}
          onDelete={() => {
            setParticipantViewModalIsOpen(false)

            console.log(selectedIndex)
            let temp = participants.filter(participant => participant.index !== selectedIndex)
            console.log(participants)
            console.log(temp)
            setParticipans(temp)
            setAlreadyParticipated(false)
            setParticipateModalIsOpen(false)
          }

          } />
      )}
      {searchModalIsOpen && (
        <SearchCampaignModal participants={participants}
          key={"sm" + searchModalIsOpen}
          isOpen={searchModalIsOpen}
          setOpen={setSearchModalIsOpen}
          onParticipate={_onParticipate}
          onIndexSelected={(index) => {

            if (index > 1000) {
              setStartIndex(index - 1000)
            }
            else {
              setStartIndex(0)
            }
            _animateIndex(index)
          }}
        />
      )}
    </>
  )

  function _loadCampaign() {
    CampaignHttpService.loadCampaigns().then(campaigns => {
      setCompaigns(campaigns);
      
      if (campaigns) {
        let _campaign = campaigns[0];
        setCompaign(_campaign);

        // ? should I set states seperately ? or just use campaign data ? 
        _setSquareOptions(_campaign);

        // _getCampaignParticipants(_campaign.id);
      }
      setLoading(false);
    });
  }

  function SquareItem(index, participant, _scaleUp, _delay) {
    return <div key={`square${index}`}
      // style={{ animationDuration: `${_duration}s !important`}}
      className={`square ${squaresAreVisible ? "" : "hide"} ${participant ? 'square-zoom' : ""}  ${index > 2500 ? 'hide-on-xsmall' : ""}`}

      style={{ margin: squareMargin, width: squareWidth ?? "1%" }}
      onClick={() => { _handleSquareClick(index + 1); } }
    >
      {(participant && (participant.thumb || participant.image)) ? (
        <img className={`${playing ? animationClass : ""}  ${_scaleUp ? "square-scaleUp" : ""}`} src={participant.thumb ? participant.thumb : participant.image}
          style={{ opacity: _scaleUp ? 1 : squareOpacity, animationDelay: `${_delay}s` }}
          alt="" />
      ) :
        addingImageIsAllowed ?

          (
            <div className='empty-square' title="Add your image here">

              <img src="assets/images/plus.png" className='hide transition' alt="" />
            </div>
          )
          : (
            <div className='empty-square'>

            </div>
          )}
    </div>;
  }

  function CampaignMenu() {
    return <div className="campaign-menu hide-on-small">
      {addingImageIsAllowed && (
        <button onClick={_onAddNew} className="btn btn-dark mr-2" title="Add your photo"><i className='fa fa-plus'></i></button>
      )}
      <button onClick={_onPlayMusic} className="btn btn-dark  mr-2" title={`${isPlaying ? 'Pause' : 'Play'}`}><i className={`fa fa-music ${playing ? 'color-gold' : ''}`}></i></button>
      <Link to={`/gallery/`} className="btn-l btn btn-dark mr-2 font-size-14 " title={`Gallery`}><i className={`fa fa-image mt-6p`}></i></Link>
      {/* <button onClick={() => { setIsPlaying(false); setSearchModalIsOpen(true); } } title="Search in campaign " className="btn btn-dark"><i className='fa fa-search'></i></button> */}
      {campaign &&
        (
          <img src={`${campaign.thumb}`}
            className={`${squaresAreVisible ? "grayscale" : ""}`}
            alt=""
            title="View background"
            onClick={() => { setSquaresAreVisible(!squaresAreVisible); } } />
        )}

      {show_tooltip &&
        (
          <div className="play-music-tooltip tooltip-fadeIn">
            <i className="fa fa-caret-up"></i>
            {_t("Play music")}
          </div>
        )}

    </div>;
  }
}

