import React, { useContext, useEffect, useState, } from 'react'
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import { UserContext } from '../../contexts/UserContext';
import { translate, Utils } from '../../utils';


const ResponsiveMenu = ({ show, hideMenu, ...props }) => {

	const { authenticate } = useContext(UserContext);
	const { setLanguage, translations } = useContext(LanguageContext);
	const _t = (key) => {
		return translate(key, translations)
	}

	const _search = (e) => {
		e.preventDefault()
		if (props.onSearch) props.onSearch()
		hideMenu()
	}

	const _add = (e) => {
		console.log()
		e.preventDefault()
		if (props.onAdd) props.onAdd()
		hideMenu()
	}


	return (
		<nav className={`responsive-menu ${show ? "active" : ""}`} >
			<a href="#close-menu" onClick={hideMenu} className="pull-right color-white lan-kfont" >{_t('Close')}</a>
			<ul className="nav nav-list flex-column">
				<li className="nav-item">
					<Link className="nav-link lan-kfont lan-font-14" to={`/`}><i className='fa fa-home mr-1'></i> {_t('Home')}</Link>
				</li>
				<li className="nav-item">
					<Link className="nav-link lan-kfont lan-font-14" to={`/about`}><i className='fa fa-info-circle mr-1'></i> {_t('About')}</Link>
				</li>
				<li className="nav-item">
					<Link className="nav-link lan-kfont lan-font-14" to={`/gallery`}><i className='fa fa-picture-o mr-1'></i> {_t('Gallery')}</Link>
				</li>

				{/* <li className="nav-item">
					<a className="nav-link lan-kfont lan-font-14" href="search" onClick={_search} ><i className='fa fa-search mr-1'></i> {_t('Search')}</a>
				</li>
				<li className="nav-item">
					<a className="nav-link lan-kfont lan-font-14" href="add" onClick={_add} ><i className='fa fa-plus mr-1'></i> {_t('Add photo')}</a>
				</li> */}
				<li className="nav-item">
					{authenticate ? (
						<a href="/?signout=1" className="nav-link lan-kfont lan-font-14"><i className='fa fa-sign-out mr-1'></i> {_t('Sign out')}</a>
					) : (
						<a href="/?login" onClick={_add} className="nav-link lan-kfont lan-font-14"><i className='fa fa-sign-in mr-1'></i> {_t('Login')}</a>
					)}
				</li>
				<li className='ml-5 language-menu-item'><Link to="?lan=en"> <i className="fa fa-globe"></i> {Utils.getCurrentLanguageName()}</Link>
					<ul>
						<li onClick={(e) => { e.preventDefault(); setLanguage("ku") }} className="kfont"><Link to={`/?lan=ku`}>کوردی</Link></li>
						<li onClick={(e) => { e.preventDefault(); setLanguage("fa") }} className="kfont"><Link to={`/?lan=fa`}>فارسی</Link></li>
						<li onClick={(e) => { e.preventDefault(); setLanguage("en") }} ><Link to={`/?lan=en`}>English</Link></li>
					</ul>
				</li>

			</ul>
		</nav>

	)

}
export default ResponsiveMenu
