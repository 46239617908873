import React, { useState } from 'react'
import LoginForm from '../forms/user/LoginForm';
import SocialLogin from '../forms/user/SocialLogin';
import CustomModal from './CustomModal';
import RegisterModal from './RegisterModal';

function LoginModal({ ...props }) {
  const handleClose = () => {
    props.setOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [registerModalIsOpen, setRegisterModalIsOpen] = useState(false);


  const _onLoggedIn = () => {
    setRegisterModalIsOpen(false)
    if (props.onLoggedIn) props.onLoggedIn()
  }


  return (
    <>
      <CustomModal showClose={1} className="p-6" key={props.isOpen} setOpen={(value) => props.setOpen(value)}
        open={props.isOpen}  >

        {/* <LoginForm onLoggedIn={props?.onLoggedIn} goToRegister={props.goToRegister}/> */}
        <SocialLogin goToEmailRegister={() => { setRegisterModalIsOpen(true) }} setError={setError} setLoading={setLoading} onLoggedIn={() => { if (props.onLoggedIn) props.onLoggedIn() }} />
        {error && (
          <>
            <div className="alert alert-danger mt-4">
              {error}
            </div>
          </>
        )}
      </CustomModal>

      <RegisterModal key={"rm" + registerModalIsOpen} onLoggedIn={_onLoggedIn} goToLogin={() => { setRegisterModalIsOpen(false); props.setOpen(true) }} isOpen={registerModalIsOpen} setOpen={setRegisterModalIsOpen} />


    </>
  )
}

export default LoginModal