import { Alert } from '@mui/material'
import React from 'react'

function Done({message="Changes are saved successfully"}) {
  return (
    <Alert className='mt-3 mb-3' severity="success">
        {message}                        
    </Alert>
  )
}

export default Done