import React from 'react'
import RegisterForm from '../../../components/forms/user/RegisterForm'

function Register() {
  return (
    <div id="wrapper " className='min-h-100vh'>
        <div id="content">
            <div className="row">
                <div className="d-flex flex-column align-items-center justify-content-center ">
                        <h1 className='mt-5 mb-4'>Welcome to Register</h1>
                            <div className="col-md-3">
                                <RegisterForm /> 
                            </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Register