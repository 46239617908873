import React, { useEffect } from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translate, Utils } from '../../utils';
import GalleryItemCommentForm from '../forms/gallery/GalleryItemCommentForm';
import GalleryLike from './GalleryLike';
import SocialShareIcons from './SocialShareIcons';


function GalleryItemView({ galleryItem, likeChanged, ...props }) {


    const [animationClass, setAnimationClass] = useState("");
    const [refresh, setRefresh] = useState(0);



    const { translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }

    useEffect(() => {
        setRefresh(refresh + 1)
    }, [galleryItem])

    const _resetAnimation = () => {
        setTimeout(function () {
            setAnimationClass("")
        }, 500);
    }

    const _next = () => {
        setAnimationClass("fadeInRight")
        props?.next()
        _resetAnimation()
        setRefresh(refresh + 1)
    }

    const _previous = () => {
        setAnimationClass("fadeInLeft")
        props?.previous()
        _resetAnimation()
        setRefresh(refresh + 1)
    }



    return (
        <>
            <div className="">
                <div className="gallery-arrows">

                    <div className="arrow previous" onClick={_previous}>
                        <i className="fa fa-chevron-left"></i>
                    </div>
                </div>
                <div className={`text-middle animated ${animationClass} gallery-item-view text-light`} >
                    <div className="row">
                        <div className="col-md-8 relative l-no-padding">
                            <div className="gallery-arrows">
                                <div className="arrow next " onClick={_next} >
                                    <i className="fa fa-chevron-right"></i>
                                </div>
                            </div>

                            <img key={refresh+"imgkey"} src={galleryItem.image} alt={galleryItem.name} className="full-width " />
                            <GalleryLike
                                key={"gl" + galleryItem}
                                liked={galleryItem.is_liked}
                                likeChanged={likeChanged}
                                galleryItem={galleryItem} />
                            <div className='gv-social-container'>
                                <SocialShareIcons url={`https://jina.nu/gallery?art=${galleryItem.id}&name=${(galleryItem?.name + "").replace(/ /g, "-")}`} />
                            </div>
                        </div>
                        <div className="col-md-4 no-padding padding-right-10">
                            <div className="caption">
                                <div>
                                    <h3 className='text-shadow-1 gallery-item-name'>{galleryItem.name}</h3>
                                    <div className="">
                                        {galleryItem.birth_date && (
                                            <div className='gallery-item-description' >
                                                <span className='color-dark-orange mr-2'>{_t("Born:")} </span> {new Date(galleryItem.birth_date).toDateString()} {galleryItem.city && galleryItem.city.name}<br />
                                                <span className='color-dark-orange mr-2'>{_t("Died:")} </span> {new Date(galleryItem.second_date).toDateString()} {galleryItem.end_city && galleryItem.end_city.name}  <br />
                                            </div>
                                        )}
                                        <div className='gallery-item-description mt-4' dangerouslySetInnerHTML={{ __html: Utils.nl2br(galleryItem.description) }} />
                                    </div>
                                </div>


                                <div className=" full-width">
                                    <GalleryItemCommentForm key={"gicf" + refresh} galleryItemId={galleryItem?.id} />
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GalleryItemView