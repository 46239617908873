import { useEffect } from 'react';
import { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function ImageCrop({ file, ...props }) {
	const [src, setSrc] = useState();
	const [crop, setCrop] = useState({
		aspect: 1 / 1, unit: '%', // Can be 'px' or '%'
		x: 25,
		y: 25,
		width: 50
	});
	const [image, setImage] = useState(null);
	const [output, setOutput] = useState(null);


	useEffect(() => {
		if (file) {
			setSrc(URL.createObjectURL(file))
		}

		return () => {

		}
	}, [])


	const cropImageNow = () => {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		const pixelRatio = window.devicePixelRatio;
		canvas.width = crop.width * pixelRatio;
		canvas.height = crop.height * pixelRatio;
		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
		ctx.imageSmoothingQuality = 'high';

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height,
		);

		// Converting to base64
		const base64Image = canvas.toDataURL('image/jpeg');
		setOutput(base64Image);
		if (props.onOutput) {
			props.onOutput(base64Image);
		}

		canvas.toBlob((blob) => {
			let file = new File([blob], "fileName.jpg", { type: "image/jpeg" })
			if (props.onSuccess) {
				props.onSuccess(file);
			}
		}, 'image/jpeg');

	};

	return (
		<div>
			{src && (
				<div className='crop-container'>
					<ReactCrop src={src} onImageLoaded={setImage}
						style={{ maxWidth: "100%" }}

						crop={crop} onChange={setCrop} />
					<button className="btn btn-primary p-2" onClick={cropImageNow}>Crop</button>
				</div>
			)}
		</div>
	);
}

export default ImageCrop;
