import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { Backdrop, Fade } from '@mui/material';

function CustomModal(props) {

  // const [open,setOpen] = useState(true)
  const handleClose = () => {
    // setOpen(false)
     props.setOpen(false);
    
  };


  return (
    <Modal
      open={props.open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
    >
      <Fade in={props.open}>
        <div className={`modal-dialog modal-dialog-centered ${props.medium ? "medium-modal" : ""} ${props.big ? "big-modal" : ""}`}>
          <div className={`modal-content ${props.contentClassName ? props.contentClassName : ""}`}>
            {props.title && (
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>

              </div>
            )}
            {props.showClose && (
              <div className='relative'>
                <button type="button" className="btn-close btn-close-white btn-colse-single" onClick={handleClose} data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            )}
            <div className={`modal-body ${props.className ? props.className : ""}`}>
              {props.children}
            </div>
            {props.footer &&
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleClose} data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
            }
          </div>
        </div>
      </Fade>

    </Modal>
  )
}

export default CustomModal