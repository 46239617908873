import React, { useContext } from 'react'
import Galleries from '../../components/gallery/Galleries'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import MobileMenu from '../../components/shared/MobileMenu'

function GalleryPage() {


  return (
    <div id="wrapper">

      <Header autoShow={false} />
      <div id="content" className="no-bottom  ">
        <MobileMenu />

        <Galleries />

      </div>

      <Footer />
    </div>
  )
}

export default GalleryPage