import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from "react-router-dom";
import Footer from '../../../components/shared/Footer';
import Header from '../../../components/shared/Header';
import CityList from './CityList';

function CityPage() {


  const { id } = useParams();

  useEffect(() => {

    _getData()

    return () => {
    }
  }, [])

  const _getData = () => {
    // setLoading(true);
  }


  return (
    <div id="wrapper">
      <Header />
      <div id="content" className='container mt-4 pb-0'>
        <div className='box'>
          <div className="box-body">
            <div className="row">
              {/* <MainMenu /> */}
              <div className="col-md-12">
                <CityList gallery={id} />
              </div>
            </div>
          </div>
        </div>


      </div>

      <Footer />
    </div>
  )
}

export default CityPage