

export class HttpService {

    static ApiBase = "https://jina.nu/api"; //https://artgallery.helanet.solutions/api
    static SiteBase = "https://jina.nu";
    static TOKEN_KEY = "artgtok22";
    

    headers = new Headers();
    
    static getToken = () => {
        return localStorage.getItem(HttpService.TOKEN_KEY) || null;
    };

    static setToken = (token) => {
        localStorage.setItem(HttpService.TOKEN_KEY,token);
    };

    static setUser = (user) => {
        localStorage.setItem("authenticate",JSON.stringify(user));
    };

    static getUser = () => {
        let token = localStorage.getItem(HttpService.TOKEN_KEY) || null
        if(!token) return null;
        return JSON.parse(localStorage.getItem("authenticate"));
    };

    static signOut = () => {
        localStorage.removeItem("authenticate");
        localStorage.removeItem(HttpService.TOKEN_KEY);
    };

    getUserToken = () => {
        return localStorage.getItem(HttpService.TOKEN_KEY) || null;
    };

    setHeaders() {
       this.headers.append("Content-Type", "application/json");
       this.headers.append("Accept", "application/json");    
        const auth = this.getUserToken();
        if (auth) this.headers.append("Authorization", "Bearer " + auth);

    }

    

    async get(url,body)  {
        // this.setHeaders();
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");   
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   


        const response = await fetch(`${HttpService.ApiBase}${url}`, {
        headers: new Headers(headers),
        method: 'GET',
        });

        const data = await response.json();

        if(response.status === 401)
        {
            window.localStorage.removeItem("authenticate");
            HttpService.setToken("")
            window.location.reload();
        }

        return data;
    }

    async post(url,body,hasFile)  {
        var headers = new Headers();
        if(!hasFile)
            headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth); 

        console.log("POST request " + url);

        
        const response = await fetch(`${HttpService.ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'POST',
            body: hasFile ? body : JSON.stringify(body),
        });



        const data = await response.json();

        console.log(data)

        return data;
    }

    async put(url,body,hasFile=false)  {

        console.log(JSON.stringify(body))

        var headers = new Headers();
        if(!hasFile)
            headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   
        
        const response = await fetch(`${HttpService.ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'PUT',
            body: hasFile ? body : JSON.stringify(body),
        });

        const data = await response.json();

        // console.log(response.body)


        return data;
    }

    async patch(url,body,hasFile)  {
        
        var headers = new Headers();
        if(!hasFile)
            headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   
        
        const response = await fetch(`${HttpService.ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'PATCH',
            body: hasFile ? body : JSON.stringify(body),
        });

        const data = await response.json();

        return data;
    }

    async delete(url) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   

        const response = await fetch(`${HttpService.ApiBase}${url}`, {
        headers: new Headers(headers),
        method: 'DELETE',
        });

        return ;
    }

    async updateActive(url,is_active) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json"); 
        const auth = this.getUserToken();
        if (auth) headers.append("Authorization", "Bearer " + auth);   

        var body = {
            "is_active" : is_active
        }

        const response = await fetch(`${HttpService.ApiBase}${url}`, {
            headers: new Headers(headers),
            method: 'PATCH',
            body: JSON.stringify(body),
        });

        const data = await response.json();

        return data;
    }

    

    
}

