import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { Error } from '@mui/icons-material';
import { HttpService } from '../../../services/http';
import { useState } from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translate } from '../../../utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialog({ url, isOpen, message="Are you sure you want to delete this item ?", method = "delete", body, setOpen, notifyDone }) {

  const [loading, setLoading] = useState(false)
  const { setLanguage , translations } = useContext(LanguageContext);
	const _t = (key)=>{
		return translate(key,translations)
	}

  const handleDelete = () => {
    // setOpen(false);
    if(loading) return false;

    setLoading(true);    
    var http = new HttpService()
    if (method === "delete")
      http.delete(url).then(() => {
        setLoading(false);
        if (notifyDone !== undefined)
          notifyDone()
      })
    else if (method === "patch") {
      http.patch(url, body).then(() => {
        setLoading(false);
        if (notifyDone !== undefined)
          notifyDone()
      })
    }
  };

  const handleClose = () => {
    // console.log("delete")
    setOpen(false);
  };



  return (
    <div>

      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            handleDelete()
          }
          if (e.key == "Escape") {
            handleClose()
          }
        }}
      // onKeyDown={(e)=>{
      //   if (e.key === 'Enter') {

      //   }
      //   }}
      >
        <DialogTitle>
          <div className="dialog-icon">
            <Error style={{ fontSize: '58px' }} />
          </div>
          <div className='dialog-delete-message'>
            {_t(message)}
          </div>
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>{_t("No")}</Button>
          <Button onClick={handleDelete}>{loading ? "..." : _t("Yes")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}