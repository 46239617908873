import React, { useContext, useState } from 'react'
import { useForm } from "react-hook-form";
import { LanguageContext } from '../../../contexts/LanguageContext';
import { UserContext } from '../../../contexts/UserContext';
import { HttpService } from '../../../services/http';
import { signInWithFacebook, signInWithGoogle, signInWithTwitter } from '../../../services/third-party/firebase';
import { UserHttpService } from '../../../services/user';
import { translate, Utils } from '../../../utils';
import parse from 'html-react-parser'

function SocialLogin({ setLoading, setError, ...props }) {

  
  const { translations } = useContext(LanguageContext);
	const _t = (key)=>{
		return translate(key,translations)
	}

  const { setAuthenticate } = useContext(UserContext);

  const _signInWithSocial = async (provider = "google") => {

    
    setLoading(true);
    setError("")
    var response;
    if (provider === "facebook") {
      response = await signInWithFacebook()
    }
    if (provider === "google") {
      response = await signInWithGoogle()
    }

    if (provider === "twitter") {
      response = await signInWithTwitter()
    }

    console.log(response)

    if (response?.uid) {
      UserHttpService.registerSocial(response).then((user) => {
        setLoading(false);
        if (user.role) { // user saved successfully
          // setDone(true)

          HttpService.setToken(user.access)
          HttpService.setUser(user)

          setAuthenticate(user)
          if (props.onLoggedIn) {
            props.onLoggedIn(user)
          }
          else {
            if (response.role == 'admin') {
              window.location.href = "/cms"
            }
            else {
              window.location.href = "/"
            }
          }
        }

      })
    }
    else {
      setLoading(false);
      setError(_t("an error occurred while signing in"));
    }
  }



  return (
    <div className="d-flex flex-column align-items-center social-images lan-kfont lan-rtl">
      <p className="text-center">{parse(Utils.nl2br(_t("Please sign up or sign in to participate")))}</p>
      <div className="d-flex justify-content-center py-3">
        <div className="mr-3" onClick={() => { _signInWithSocial("facebook") }} title="Sign in with facebook"><img src="/assets/images/facebook.png" alt="" /></div>
        <div className="mr-3" onClick={() => { _signInWithSocial("google") }} title="Sign in with google"><img src="/assets/images/google.png" alt="" /></div>
        <div className="mr-3" onClick={() => { _signInWithSocial("twitter") }} title="Sign in with twitter"><img src="/assets/images/twitter.png" alt="" /></div>
        {props?.goToEmailRegister && (
          <div className="" title="Sign in with email and password" onClick={() => { props.goToEmailRegister() }}><img src="/assets/images/email.png" alt="" /></div>
        )}
      </div>
    </div>
  )
}

export default SocialLogin