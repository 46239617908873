import React, { useEffect } from 'react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { LanguageContext } from '../../../contexts/LanguageContext'
import { translate } from '../../../utils'
import CampaignParticipants from '../../components/list/campaign/CampaignParticipants'
import UsersList from '../../components/list/user/UsersList'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'

function Users() {

  const { setLanguage, translations } = useContext(LanguageContext);
  const _t = (key) => {
    return translate(key, translations)
  }

  return (
    <div id="wrapper">
      <Header />



      <div id="content" className='container mt-4 pb-0'>
        <div className="row">
          {/* <MainMenu /> */}
          <div className="col-md-12">
            <div className='box'>
              <div className="box-header">
                <h5>Users</h5>
                <div className="btns">
                  <Link to="/cms/user/add" className="btn"><i className="fa fa-plus"></i></Link>
                </div>
              </div>
              <div className="box-body lan-rtl">
                <div className="tab-default">

                  <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <button className="nav-link lan-kfont active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">{_t("Users")}</button>
                      <button className="nav-link lan-kfont" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">{_t("Administrators")}</button>
                    </div>
                  </nav>

                  <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      <UsersList role="participant" />
                    </div>
                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                      <UsersList role="admin" />
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      <Footer />
    </div>
  )
}

export default Users