import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from "react-router-dom";
import { BaseHttpService } from '../../../../services/base'
import CustomModal from '../../../../components/modals/CustomModal'
import GalleryItemForm from '../../../components/forms/gallery/GalleryItemForm'
import DeleteDialog from '../../../components/shared/DeleteDialog'
import CityForm from '../../../components/forms/settings/CityForm';

function CityList({ gallery }) {

  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = React.useState(false);

  const [selectedId, setSelectedId] = React.useState(0);
  const { id } = useParams();
  const [refresh, setRefresh] = React.useState(0);

  useEffect(() => {

    _getData()

    return () => {
    }
  }, [])

  const _getData = () => {
    setLoading(true);
    BaseHttpService.loadCities().then((items) => {
      setData(items)
    });
  }


  const _handleDelete = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setDeleteDialogIsOpen(true)
  }

  const _onDeleteDone = () => {
    var temp = data.filter(item => item.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh + 1)
  }

  const _edit = (e, item) => {
    e.preventDefault();
    setSelectedId(item.id);
    setEditModalIsOpen(true)
    setRefresh(refresh + 1)
  }



  return (
    <div className='box'>
      <div className="box-header mb-5">
        <h5>Cities</h5>
        <div className="btns">
          <button className="btn btn-icon" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i class="fa fa-plus "></i></button>
        </div>
      </div>
      <div className="box-body d-flex flex-wrap">
        {data?.map(item => (
          <div key={item.id + "cmp"} className='city-item'>
           
            <div className="caption">
              <h3> {item.name}</h3>
              <div className="btns">
                <button className="btn" onClick={(e) => { _edit(e, item) }}>
                  <a href="#edit"  >
                    <i className="fa fa-edit"></i>
                  </a>
                </button>
                <button className="btn" onClick={(e) => { e.preventDefault(); _handleDelete(e, item.id) }}>
                  <a href="#delete" >
                    <i className="fa fa-trash"></i>
                  </a>
                </button>
              </div>
            </div>
          </div>
        )
        )}
      </div>

      <CustomModal className="" key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
        open={editModalIsOpen} >

        <CityForm key={`gmd${selectedId}`} id={selectedId}
          onEditDone={(city) => {
            let index = data.findIndex(item => item.id === city.id);
            if (index > 0) {
              data[index].name = city.name;
            }
            else {
              data.push(city);
            }
            setData(data)
          }}
        />

      </CustomModal>

      <DeleteDialog
        url={`/base/city/${selectedId}`}
        isOpen={deleteDialogIsOpen}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={_onDeleteDone}
      />
    </div>
  )
}

export default CityList