import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { BaseHttpService } from '../../services/base'
import Constants from '../../utils/constants'

function MetaTags({ image = Constants.SiteDefaultImage }) {


    const [setting, setSetting] = useState()

    useEffect(() => {
        BaseHttpService.loadSettings().then(settings => {
            if (settings.length > 0)
                setSetting(settings[0])
        })
    }, [])

    return (
        <Helmet>
            <title>{setting && setting.site_title ? setting.site_title : Constants.SiteDefaultTitle}</title>
            <link rel="canonical" href="https://jina.nu/" />
            <meta name="description" content={setting && setting.site_description ? setting.site_description : Constants.SiteDefaultDescription} />

            {/* <!-- Google / Search Engine Tags --> */}
            <meta itemprop="name" content={setting && setting.site_title ? setting.site_title : Constants.SiteDefaultTitle} />
            <meta itemprop="description" content={setting && setting.site_description ? setting.site_description : Constants.SiteDefaultDescription} />
            <meta itemprop="image" content={image} />

            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content="https://jina.nu" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={setting && setting.site_title ? setting.site_title : Constants.SiteDefaultTitle} />
            <meta property="og:description" content={setting && setting.site_description ? setting.site_description : Constants.SiteDefaultDescription} />
            <meta property="og:image" content={image} />
            <meta property="og:image:type" content="image/jpg" />
            <meta property="og:image:width" content="789" />
            <meta property="og:image:height" content="444" />

            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={setting && setting.site_title ? setting.site_title : Constants.SiteDefaultTitle} />
            <meta name="twitter:description" content={setting && setting.site_description ? setting.site_description : Constants.SiteDefaultDescription} />
            <meta name="twitter:image" content={image} />

        </Helmet>
    )
}

export default MetaTags