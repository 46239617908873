import { Favorite, ThumbDown, ThumbsUpDownOutlined, ThumbUpSharp } from '@mui/icons-material';
import React from 'react'
import { useEffect } from 'react';
import { BaseHttpService } from '../../services/base';
import { HttpService } from '../../services/http';
import LoginModal from '../modals/LoginModal';

function GalleryItemCommentLike({ galleryItemComment, likeChanged }) {

    const [loading, setLoading] = React.useState(false);
    const [liked, setLiked] = React.useState(galleryItemComment?.is_liked ?? false);
    const [disliked, setDisiked] = React.useState(galleryItemComment?.is_disliked ?? false);
    const [likes_count, setLikesCount] = React.useState(galleryItemComment?.likes_count);
    const [dislikes_count, setDislikesCount] = React.useState(galleryItemComment?.dislikes_count);
    const [loginModalIsOpen, setLoginModalIsOpen] = React.useState(false);


    const _like = () => {

        var user = HttpService.getUser()

        if (!user) {
            setLoginModalIsOpen(true)
            return;
        }

        setLiked(!liked)
        // likeChanged(!liked)
        if (!liked) {
            setLikesCount(likes_count + 1)
        }
        else {
            setLikesCount(likes_count - 1)
        }

        _saveGalleryItemCommentLike(!liked, disliked)
    }

    const _dislike = () => {
        
        var user = HttpService.getUser()

        if (!user) {
            setLoginModalIsOpen(true)
            return;
        }

        setDisiked(!disliked)
        // likeChanged(!disliked)
        if (!disliked) {
            setDislikesCount(dislikes_count + 1)
        }
        else {
            setDislikesCount(dislikes_count - 1)
        }

        _saveGalleryItemCommentLike(liked, !disliked)
    }

    const _saveGalleryItemCommentLike = (liked, disliked) => {
        setLoading(true)
        let body = {
            gallery_item_comment: galleryItemComment.id,
            is_liked: liked,
            is_disliked: disliked,
        }

        console.log(body)

        BaseHttpService.saveGalleryItemCommentLike(body).then(response => {
            if (response.id) {
                setLiked(response.is_liked)
                setDisiked(response.is_disliked)


                setLoading(false)
            }
        })
    }

    return (
        <>
            <div className="gallery-item-comment-like" >
                <div className={`heart ${liked ? "liked" : ""}`} onClick={(e) => { e.preventDefault(); _like() }}>
                    <ThumbUpSharp />
                    &nbsp;&nbsp;
                    {likes_count>0 && likes_count}
                </div>
                <div className={`heart ${disliked ? "liked" : ""}`} onClick={(e) => { e.preventDefault(); _dislike() }}>
                    <ThumbDown />
                    &nbsp;&nbsp;
                    {dislikes_count>0 && dislikes_count}
                </div>

            </div>
            <LoginModal key={"lm" + loginModalIsOpen}
                onLoggedIn={() => { setLoginModalIsOpen(false) }}
                isOpen={loginModalIsOpen} setOpen={setLoginModalIsOpen}

            />
        </>
    )
}

export default GalleryItemCommentLike