import React, { useState } from 'react'
import { useContext } from 'react';
import { useForm } from "react-hook-form";
import { LanguageContext } from '../../../contexts/LanguageContext';
import { HttpService } from '../../../services/http';
import { UserHttpService } from '../../../services/user';
import { translate } from '../../../utils';
import LoginForm from './LoginForm';
function RegisterForm({ ...props }) {

  const { translations } = useContext(LanguageContext);
	const _t = (key)=>{
		return translate(key,translations)
	}

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logging_in, setLoggingIn] = useState(false);
    const [current_error, setCurrentError] = useState(false);

  const saveChanges = (data) => {
    setCurrentError(false)

    var body = {
      "role": "participant",
      "username": data.email,
      "email": data.email,
      "first_name": data.first_name,
      "last_name": data.last_name,
    }


    if (data.password) {
      if (data.password.length > 5) {
        body['password'] = data.password
      } else {
        var message = 'Password length must be at least 6 characters'
        setCurrentError(message)
        setError("password", message)
        return
      }
    }

    setDone(false)
    setLoading(true)

    UserHttpService.saveUser(body).then(user => {
      setLoading(false)
      if (user.role) { // user saved successfully
        setDone(true)

        var body = {
          "username": data.email,
          "password": data.password
        }
        setLoggingIn(true)

        if (props.onLoggedIn != null) {
          UserHttpService.login(body).then(response => {
            setLoggingIn(false);
            if (response.detail) {
              alert("error")
            }
            else {
              HttpService.setToken(response.access)
              HttpService.setUser(response)
              props.onLoggedIn(response)
            }
          });
        }
      }
      else {
        if (user.username) {
          let message = "This username has already taken"
          setCurrentError(message)
          setError("username", message)
        }
        else {
          let message = "Something went wrong please review the form"
          setCurrentError(message)
          setError("username", message)

        }
      }

    }).catch(err => {
      setLoading(false)
      setCurrentError("Something went wrong please review the form")
    });
  }

  return (
    <>
      {/* <div className="mb-2">
        <SocialLogin setError={setError} setLoading={setLoading} onLoggedIn={() => { if (props.onLoggedIn) props.onLoggedIn() }} />
      </div> */}
      <div className="mb-4 pointer " onClick={props.goToLogin}>
        <i className="fa fa-arrow-left color-gray"></i>
      </div>
      <LoginForm onLoggedIn={(user) => {  if (props.onLoggedIn) props.onLoggedIn(user) }} goToRegister={props?.goToRegister} />
      <form onSubmit={handleSubmit(saveChanges)} className='styled_form' >

        <div className="row" >
          <div className="mb-4 lan-kfont lan-rtl">
            {_t("Or create a new account")}
          </div>
          <div className='col-md-6' >
            <input type="text"    {...register("first_name", { required: true })}
              className={`form-control lan-kfont lan-rtl ${errors.first_name ? "has_error" : ""} `} placeholder={_t("Name")} />
          </div>
          <div className='col-md-6'>
            <input type="text" error={errors.last_name} {...register("last_name", { required: true })} className={`form-control lan-rtl lan-kfont ${errors.last_name ? "has_error" : ""} `} placeholder={_t("Last Name")} />
          </div>
          <div className='col-md-6' >
            <input type="email" error={errors.email} {...register("email", { required: true })} className={`form-control  lan-kfont ${errors.email ? "has_error" : ""} `} placeholder={_t("Email")} />
          </div>
          <div className='col-md-6'>
            <input type="password" error={errors.password} {...register("password", { required: true })} className={`form-control lan-kfont ${errors.password ? "has_error" : ""} `} placeholder={_t("Password")} />
          </div>
        </div>
        <div className="row " >

          <div className="col-md-12" >
            <div id="submit">
              {loading && (
                <button className="btn p-2" type="button" disabled>
                  <span className="spinner-grow text-warning spinner-grow-sm" role="status" aria-hidden="true"></span>
                  <span className="sr-only">Loading...</span>
                </button>
              )}
              {!loading && (
                <div className="pull-right d-flex align-items-center lan-rtl">
                  <div className="white pointer color-gray mr-3 lan-kfont " onClick={() => { props.setOpen(false) }}>{_t("close")}</div>
                  <input type="submit" value={_t("Register")} className="btn btn-line lan-kfont rounded-25" />
                </div>
              )}



              

            </div>
            
          </div>
          {error && (
                <>
                  <div className="alert alert-danger mt-4 lan-rtl">
                    {_t(current_error)}
                  </div>
                </>
              )}
          
          {done && (
                <>
                  <div className="alert alert-success mt-4 lan-kfont">
                    {_t('You registrerd successfully')}
                    {logging_in && <span className="lan-kfont"> {_t("signing in")}...</span>}
                    {!logging_in && <span className="lan-kfont"> {_t("now you can login to your account")}</span>}
                    
                  </div>
                </>
              )}

        </div>
      </form>


    </>
  )
}

export default RegisterForm