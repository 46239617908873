import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { BaseHttpService } from "../../../../../services/base";

function SelectCity({ register ,placeholder="City" , name="city",required=true, value }) {

    const [data,setData] = useState(null)
    const componentMounted = useRef(true);

    useEffect(() => {
          BaseHttpService.loadCities().then(data => {
            if (componentMounted.current){ // (5) is component still mounted?
              setData(data)
            }
        })

        return () => { 
            componentMounted.current = false;
        }
    }, [])
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{placeholder}</InputLabel>
      <Select
        {...register(name, { required: required })}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={placeholder}
        defaultValue={value}
      >
        {data && data.map((item)=>(
            <MenuItem  key={item.id} value={item.id}>{item.name}</MenuItem>
        ))}
        
      </Select>
    </FormControl>
  );
}

export default SelectCity;
