import { Alert } from '@mui/material'
import React from 'react'

function Error({message="Something went wrong"}) {
  return (
    <Alert className='mt-3 mb-3' severity="error">
      {message} 
    </Alert>
  )
}

export default Error