import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import CustomModal from '../CustomModal';

function SearchCampaignModal(props) {

  const [loading, setLoading] = useState(false);
  const [toggleClass, setToggleClass] = useState(false);
  const [query, setQuery] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [playing, setPlaying] = useState(0);
  const [participant, setParticipant] = useState(false);


  const txtSearchRef = useRef();
  
  useEffect(() => {

}, [])
  const myInterval = useRef();

  const _search = (e) => {
    setToggleClass(!toggleClass)
    
    var q = e.target.value;
    if (q < 0) q = q * -1;

    setQuery(q)
    setSelectedIndex(q);

    var participant = props.participants.find(item => item.index == q);
    if (participant) {
      setParticipant(participant);
    }
    else {
      setParticipant(null)
    }
  }


  const _onSubmit = (e) => {
    e.preventDefault();
    if (props.onIndexSelected) {
      props.onIndexSelected(txtSearchRef.current.value)
    }
  }

  const _playStop = () => {
    playing ? _stop() : _play()
  }

  const _play = () => {
    setPlaying(true)
    myInterval.current = setInterval(_next, 2000);
  }

  const _stop = () => {
    setPlaying(false)
    clearInterval(myInterval.current)
  }


  var currentIndex = selectedIndex;

  const _next = () => {
    setToggleClass(!toggleClass)
    setRefresh(refresh + 1)

    var participants = props.participants.filter(item => item.index > currentIndex)
      .sort((a, b) => (a.index - b.index));

    if (participants) {
      let participant = participants[0]

      setSelectedIndex(participant.index);
      currentIndex = participant.index
      setParticipant(participant);
      setQuery(participant.index)
    }
    else {
      let participant = props.participants.find(item => item.image);
      if (participant) {
        setSelectedIndex(participant.index);
        setParticipant(participant);
        setQuery(participant.index)
      }
      else {
        setParticipant(null);
        setSelectedIndex(1)
        setQuery(1)
      }
    }

  }

  const _previous = (e) => {
    setToggleClass(!toggleClass)
    var participants = props.participants.filter(item => item.index < selectedIndex)
      .sort((a, b) => (b.index - a.index));;
    if (participants) {
      let participant = participants[0]
      setSelectedIndex(participant.index);
      setParticipant(participant);

      setQuery(participant.index)
    }
    else {
      let participant = props.participants.find(item => item.image);
      if (participant) {
        setSelectedIndex(participant.index);
        setParticipant(participant);
        setQuery(participant.index)
      }
      else {
        setParticipant(null);
        setSelectedIndex(1)
        setQuery(1)
      }
    }
  }


  return (
    <>
      <CustomModal className="p-0" contentClassName={"no-bg br-30"} key={"cmpsearch" + props.isOpen} setOpen={(value) => props.setOpen(value)}
        open={props.isOpen > 0}  >
        <>
        <form onSubmit={_onSubmit} className='search-form' >
          <input type="number" ref={txtSearchRef} autoFocus placeholder="Search by index ..." value={query} onChange={_search}
           className="form-control mb-0" />
           <div className='search-button' onClick={_onSubmit}>Search</div>
          
        </form>
        {participant && (
            <div className='p-3'>
              {`${participant.user.profile.first_name} ${participant.user.profile.last_name}`}
              <div>
                <small className='color-gray'>press enter to view participant</small>
              </div>
            </div>
          )}
        </>
      </CustomModal>

    </>
  )
}

export default SearchCampaignModal