import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form';
import { BaseHttpService } from '../../../services/base';
import { Avatar, IconButton, TextField } from '@mui/material';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translate, Utils } from '../../../utils';
import LoginModal from '../../modals/LoginModal';
import { HttpService } from '../../../services/http';
import {  Send } from '@mui/icons-material';
import GalleryItemCommentList from './GalleryItemCommentList';
import LinearLoading from '../../shared/LinearLoading';
import { UserContext } from '../../../contexts/UserContext';

function GalleryItemCommentForm({ galleryItemId, ...props }) {
    const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();

    const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const [comments_refresh, setCommentsRefresh] = useState(0);
    const { profile } = useContext(UserContext);

    const { translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }

    const saveComment = (data) => {

        setError(false)

        var body = {
            "gallery_item": galleryItemId,
            "description": data.description,
        }

        var user = HttpService.getUser()

        if (!user) {
            setLoginModalIsOpen(true)
            return;
        }

        setDone(false)
        setLoading(true)
        BaseHttpService.saveGalleryItemComment(body).then(response => {
            setLoading(false)
            setDone(true)
            setValue("description", "")
            setError(false)
            setCommentsRefresh(comments_refresh + 1)
            props.callback();
        }).catch(err => {
            setLoading(false)
            // setError("Something went wrong")
        });
    };



    return (
        <div>
            <form className="mb-4" onSubmit={handleSubmit(saveComment)}>

                <div className="form-group comment-form">
                    <div className="my-avatar">
                        <Avatar src={Utils.getUserImage(profile)} />
                    </div>
                    <TextField
                        className="full-width"
                        label={_t("Leave some words here...")}
                        error={errors.description && _t("Please enter some words")}
                        value={watch('description')}
                        variant="standard"
                        {...register("description", { required: true })}
                    />
                </div>
                <div className="col-md-12 text-right ">
                    {(!loading && watch('description')) && (

                        <IconButton onClick={handleSubmit(saveComment)} className='' color="default" >
                            <Send />
                        </IconButton>
                    )
                    }

                    <LinearLoading loading={loading} />
                </div>

            </form>
            <LoginModal key={"lm" + loginModalIsOpen}
                onLoggedIn={() => { setLoginModalIsOpen(false) }}
                isOpen={loginModalIsOpen} setOpen={setLoginModalIsOpen}

            />
            <GalleryItemCommentList key={"cr" + comments_refresh} galleryItemId={galleryItemId} />

        </div>
    )
}

export default GalleryItemCommentForm