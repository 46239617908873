import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./contexts/UserContext";
import { LanguageContextProvider } from "./contexts/LanguageContext";
import MainRoutes from "./routes/MainRoutes";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function App() {

  
  return (
    <>
      <BrowserRouter>
        <UserContextProvider>
        <LanguageContextProvider>
          <ThemeProvider theme={darkTheme}>
            <MainRoutes />
          </ThemeProvider>
        </LanguageContextProvider>
        </UserContextProvider>
    </BrowserRouter>
    </>
  );
}

export default App;
