import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { CampaignHttpService } from '../../../../services/campaign';
import { HttpService } from '../../../../services/http';
import DeleteDialog from '../../shared/DeleteDialog';

function UsersList({role="participant"}) {

  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(0);

  useEffect(() => {

    _getData()

  }, [])

  const _getData = () => {
    setLoading(true)
    CampaignHttpService.loadUsers(role).then(users => {
      setUsers(users)
      setLoading(false)
    })
  }

  const _deleteItem = (e, id) => {
    e.preventDefault()
    setLoading(true);
    setUsers(users.filter(item => item.id != id))
    setRefresh(refresh + 1)
    // CampaignHttpService.deleteItem(`/api/user/${id}`).then(data=>{
    //   setLoading(false);
    // })

  }

  const _getImage = (item) => {
    var _img = "/assets/images/user.png";
    if (item.campaign_participants) {
      var _cp = item.campaign_participants[item.campaign_participants.length - 1]
      if (_cp)
        _img = HttpService.ApiBase + _cp.image
    }
    return _img;
  }

  const _getNewestIndex = (item) => {
    if (item.campaign_participants) {
      var _cp = item.campaign_participants[item.campaign_participants.length - 1]
      if (_cp)
        return _cp.index
    }
    return 0;
  }



  const _handleDelete = (e, id) => {
    console.log()
    setSelectedId(id);
    setDeleteDialogIsOpen(true)
    setRefresh(refresh + 1)
  }

  const _onDeleteDone = () => {
    var temp = users.filter(user => user.id !== selectedId)
    setUsers(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh + 1)
  }




  return (
    <div className='user-container'>
      {users && users.map(item => {

        var _img = _getImage(item)

        return (
          <div key={item.id + "cmp"} className='user-item'>
            <div className="image">
              <a href={`/cms/user/${item.id}`}>
                <img src={_img} alt={item.name} />
              </a>
            </div>
            <button className="btn btn-delete">
              <a href="#Delete" onClick={(e) => { if(item.id>1)  _handleDelete(e, item.id) }}>
                <i className="fa fa-trash"></i>
              </a>
            </button>
            <div className="caption">
              <div className="btns">
                <p className='font-white mb-1' title="newest index"># {_getNewestIndex(item)}</p>
                <p className='font-white mb-1'>{item?.profile?.first_name} {item?.profile?.last_name}</p>
                <p className='font-11'>
                  <Link className='color-orange' to={`/cms/user/${item.id}`}>{item.username}</Link>
                </p>
              </div>
            </div>
          </div>
        )
      }
      )}

      <DeleteDialog
        url={`/api/user/${selectedId}`}
        isOpen={deleteDialogIsOpen}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={_onDeleteDone}
      />
    </div>
  )
}

export default UsersList