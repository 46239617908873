import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translate } from '../../../utils';

function SelectGalleryItemSorting({onChange}) {
    
    const [value,setValue] = useState(1)
    const { translations } = useContext(LanguageContext);
    const _t = (key) => {
        return translate(key, translations)
    }

    const handleChange = (e) => {
        setValue(e.target.value)
        onChange(e.target.value);
    }
    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="select-sort-by">Sort By</InputLabel>
                <Select
                    labelId="select-sort-by"
                    id="demo-simple-select"
                    value={value}
                    label="Sort by "
                    onChange={handleChange}
                >
                    <MenuItem value="-birth_date">Date of Birth</MenuItem>
                    <MenuItem value={"-second_date"}>Date of Death</MenuItem>
                    <MenuItem value={"-city"}>Place of Birth</MenuItem>
                    <MenuItem value={"-end_city"}>Place of Death</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default SelectGalleryItemSorting